import React from 'react'
import SearchEngineOptimization from '../../../components/SEO'
import OperatorEditContainer from '../../../containers/OperatorEditContainer'
import LayoutMain from '../../../layout'

const EditOperatorPage: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Editar Operador" />
      <OperatorEditContainer />
    </LayoutMain>
  )
}

export default EditOperatorPage
