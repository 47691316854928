// Packages
import React, { useEffect, useState } from 'react'
import { useForm } from 'antd/lib/form/Form'
import { toast } from 'react-toastify'
import moment from 'moment'
import { mapKeys, isEmpty } from 'lodash'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { useQuery, useMutation, ApolloError } from '@apollo/client'
import { LeftOutlined } from '@ant-design/icons'
// Components
import FormOperator from '../../components/FormOperator'
import HeaderModule from '../../components/HeaderModule'
import Loading from '../../components/Shared/Loading'
import CustomButton from '../../components/Storybook/customButton'
import Permissions from '../../components/Permissions'
import ModalResult from '../../components/Shared/ModalResult'
import ModalResponse from '../../components/Shared/ModalResponse'
import { StyledBackButton } from '../OperatorsContainer/styledComponents'
// Utils
import { getParameter } from '../../utils/queryString'
import useErrorHandler from '../../hooks/useErrorHandler'
import { setGA } from '../../utils/workers'
// GraphQL
import {
  GET_OPERATOR_BY_ID,
  GET_PERMISSIONS_LIST
} from '../../graphql/queries/operators'
import { UPDATE_DATA_OPERATOR } from '../../graphql/mutation/operators'
import { PermissionsTypes } from '../../types/PermissionsTypes'

type propsResult = {
  isModalVisible: boolean
  subTitle: any
  action: () => void
}

const resultInitialState: propsResult = {
  isModalVisible: false,
  subTitle: <></>,
  action: () => null
}

const OperatorEditContainer: React.FC = () => {
  const { pathname } = useLocation()
  const [initialValues] = useState<any>({
    APP_PEM_CASH_REGISTER_00: true,
    APP_PEM_MODELORAMA_CLUB_00: true,
    APP_PEM_INVENTORY_03: true,
    APP_PEM_REPORTS_SALES_02: true
  })
  const [result, setResult] = useState(resultInitialState)
  const [OperatorForm] = useForm()
  const [step, setStep] = useState(1)
  const [activeTabEdit, setActiveTabEdit] = useState('1')
  const [disabledForm, setDisabledForm] = useState(true)
  const [apolloError, setApolloError] = useState<ApolloError>()
  const errorMessage = useErrorHandler(apolloError, false)
  const {
    data: operatorData,
    loading: operatorLoading,
    refetch: operatorRefetch
  } = useQuery(GET_OPERATOR_BY_ID, {
    variables: { collaboratorId: getParameter('collaboratorId') },
    fetchPolicy: 'cache-and-network'
  })
  const { data: permissionsData } =
    useQuery<PermissionsTypes.Permissions>(GET_PERMISSIONS_LIST)

  const [updateDataOperator, { loading: loadingUpdateOperator }] =
    useMutation(UPDATE_DATA_OPERATOR)

  const getPermissionsOperator = (
    permissions: any,
    permissionsOperator: any = {}
  ) => {
    permissions.forEach((permission: any) => {
      permission.modules.forEach((module: any) => {
        module.actions.forEach((action: any) => {
          permissionsOperator = {
            ...permissionsOperator,
            [action.actionID]: true
          }
        })
      })
    })

    return permissionsOperator
  }

  useEffect(() => {
    if (!operatorLoading && operatorData) {
      fieldOperatorData(operatorData)
    }
  }, [operatorData, operatorLoading])

  useEffect(() => {
    if (errorMessage && apolloError) {
      toast.error(errorMessage)
      setApolloError(undefined)
    }
  }, [errorMessage, apolloError])

  const fieldOperatorData = (operatorData: any) => {
    OperatorForm.setFieldsValue({
      ...getPermissionsOperator(operatorData.getCollaborator.permissions),
      birthdate: moment(operatorData.getCollaborator.birthdate, 'YYYY-MM-DD'),
      name: operatorData.getCollaborator.name,
      familyName: operatorData.getCollaborator.familyName,
      middleName: operatorData.getCollaborator.middleName,
      mobilePhone: operatorData.getCollaborator.mobilePhone
    })
  }

  const getArrayPermissions = (data: any) => {
    const permissions: Array<string> = []
    mapKeys(data, (value, key) => {
      if (key.includes('APP_PEM') && value === true) {
        permissions.push(key)
      }
    })
    return permissions
  }

  const subTitleSaveModal = (
    <>
      <span>¿Estás seguro de</span>
      <h4>
        <b>guardar los cambios?</b>
      </h4>
    </>
  )

  const subTitleCancelModal = (
    <>
      <span>¿Estás seguro de</span>
      <h4>
        <b>cancelar los cambios?</b>
      </h4>
    </>
  )

  const subTitleCancelTab = (
    <>
      <span>¿Estás seguro que deseas cambiar de sección y</span>
      <h4>
        <b>descartar los cambios?</b>
      </h4>
    </>
  )

  const onFinish = (data: any) => {
    setResult({
      isModalVisible: true,
      subTitle: subTitleSaveModal,
      action: () => handleUpdateOperator(data)
    })
  }

  const handleUpdateOperator = async (data: any) => {
    onCancelModal()
    setDisabledForm(true)
    const operator: any = {
      collaboratorId: operatorData.getCollaborator.collaboratorId,
      name: data.name,
      middleName: data.middleName,
      familyName: data.familyName,
      genderID: 1,
      mobilePhone: data.mobilePhone,
      birthdate: moment(data.birthdate).format('YYYY-MM-DD'),
      permissions: isEmpty(getArrayPermissions(data))
        ? getArrayPermissions(
            getPermissionsOperator(operatorData.getCollaborator.permissions)
          )
        : getArrayPermissions(data)
    }

    try {
      await updateDataOperator({
        variables: operator
      })
      setGA(data, 'edit')
      setActiveTabEdit('1')
      toast.success('Cambios guardados con éxito')
    } catch (error: any) {
      operatorRefetch()
      setActiveTabEdit('1')
      setApolloError(error)
    }
  }

  const onFieldsChange = () => {
    setDisabledForm(false)
    const fields = [
      'name',
      'middleName',
      'familyName',
      'mobilePhone',
      'birthdate'
    ]
    const minAge = moment().subtract(18, 'y')
    setDisabledForm(
      !!OperatorForm.getFieldsError().filter(({ errors }) => errors.length)
        .length
    )

    // The user fill the first step
    fields.forEach((element) => {
      if (!OperatorForm.getFieldValue(element)) {
        setDisabledForm(true)
      }
    })

    // The user is an adult
    if (minAge < OperatorForm.getFieldValue('birthdate')) {
      setDisabledForm(true)
      OperatorForm.setFields([
        {
          name: 'birthdate',
          errors: ['El operador debe ser mayor de edad']
        }
      ])
    }
  }

  const onCancel = () => {
    setResult({
      isModalVisible: true,
      subTitle: subTitleCancelModal,
      action: () => {
        setDisabledForm(true)
        onCancelModal()
        OperatorForm.resetFields()
        fieldOperatorData(operatorData)
      }
    })
  }

  const onCancelModal = () => setResult(resultInitialState)

  const backButton = (
    <StyledBackButton>
      <CustomButton
        id="button-icon-back-sales-report"
        name="buttonIconBackSalesReport"
        className="button-icon-back-operators"
        dataTestId="data-test-button-icon-back-sales-report"
        icon={<LeftOutlined />}
        onClick={() => navigate('/operators')}
      />
    </StyledBackButton>
  )

  const modalContent = (
    <ModalResult
      key={`mesage-modal-${Math.random()}`}
      status={'warning'}
      subTitle={result.subTitle}
      extra={
        <>
          <CustomButton
            key={`success-button-${Math.random()}`}
            id="success-workers-edit-roles"
            name="successWorkersEditRoles"
            dataTestId="data-testid-success-workers-edit-roles"
            label="Aceptar"
            className="save-button-workers-edit-roles"
            onClick={result.action}
          />
          <CustomButton
            key={`cancel-button-${Math.random()}`}
            id="cancel-workers-edit-roles"
            name="cancelWorkersEditRoles"
            dataTestId="data-testid-cancel-workers-edit-roles"
            label="Cancelar"
            className="cancel-button-workers-edit-roles"
            onClick={onCancelModal}
          />
        </>
      }
    />
  )

  const onTabClick = (key: string) => {
    !disabledForm && key !== activeTabEdit
      ? setResult({
          isModalVisible: true,
          subTitle: subTitleCancelTab,
          action: () => {
            setActiveTabEdit(key)
            setDisabledForm(true)
            onCancelModal()
            fieldOperatorData(operatorData)
          }
        })
      : setActiveTabEdit(key)
  }

  if (operatorLoading || loadingUpdateOperator) return <Loading />

  return (
    <>
      {operatorData && (
        <>
          <HeaderModule title={'Editar Operador'} backButton={backButton} />
          <FormOperator
            step={step}
            form={OperatorForm}
            disabled={disabledForm}
            permissions={
              <Permissions
                data={permissionsData ? permissionsData.getPermissions : []}
                initialValues={initialValues}
              />
            }
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            onCancel={onCancel}
            onTabClick={onTabClick}
            activeTabEdit={activeTabEdit}
            nextStep={() => setStep(step + 1)}
            initialValues={initialValues}
            edit={pathname.includes('edit-operator') ? true : false}
          />

          <ModalResponse
            isModalVisible={result.isModalVisible}
            content={modalContent}
            onCancel={onCancelModal}
          />
        </>
      )}
    </>
  )
}

export default OperatorEditContainer
