import { gql } from '@apollo/client'

export const UPDATE_OPERATOR_STATUS = gql`
  mutation ($id: Int!, $statusID: Int!) {
    updateCollaboratorStatus(id: $id, statusID: $statusID) {
      code
      status
      message
      RowsModify
    }
  }
`

export const UPDATE_DATA_OPERATOR = gql`
  mutation UpdateCollaborator(
    $collaboratorId: Int!
    $birthdate: String!
    $familyName: String!
    $genderID: Int!
    $mobilePhone: String!
    $middleName: String!
    $name: String!
    $permissions: [String!]!
  ) {
    updateCollaborator(
      collaboratorId: $collaboratorId
      name: $name
      middleName: $middleName
      familyName: $familyName
      birthdate: $birthdate
      genderID: $genderID
      mobilePhone: $mobilePhone
      permissions: $permissions
    ) {
      code
      message
      RowsModify
    }
  }
`
